import { useState, useEffect } from "react";

// PACKAGES
import Slider from "rc-slider";

// CSS
import "rc-slider/assets/index.css";
import "./App.css";

function numberWithCommas(x) {
    if (x !== null && x !== undefined && x !== "") {
        return parseFloat(x.toString().replace(/[^0-9.]/g, "")).toLocaleString(
            "en-AU"
        );
    } else {
        return "";
    }
}

function removeCommas(x) {
    if (x !== null && x !== undefined && x !== "") {
        return x.toString().replace(/[^0-9.]/g, "");
    } else {
        return "";
    }
}

const App = () => {
    const [error, setError] = useState({ amount: "" });
    const [hideLoanType, setHideLoanType] = useState(false);
    const [quote, setQuote] = useState({
        type: "",
        usage: "",
        amount: "",
        term: 5,
        source: "",
        sourceUrl: "",
        targetSystem: "",
        externalPartnerId: "",
    });

    useEffect(() => {
        setQuote({
            ...quote,
            externalPartnerId: localStorage.getItem("externalPartnerId"),
            sourceUrl: localStorage.getItem("sourceUrl"),
            source: localStorage.getItem("source"),
            targetSystem: localStorage.getItem("targetSystem"),
        });

        // check the flag from the source whether to hide the loan type
        if (localStorage.getItem("defaultLoanType")) {
            setHideLoanType(true);
            setQuote({
                ...quote,
                type: localStorage.getItem("defaultLoanType"),
            });
        }
    }, []);

    useEffect(() => {
        if (quote.type === "BUSINESS_LOAN" && quote.term > 3) {
            setQuote({ ...quote, term: 2 });
        }
    }, [quote.type]);

    function isValidAmount(amount) {
        let status = true;

        if (amount < 5000) {
            status = false;
            setError({
                ...error,
                amount: `Unfortunately the minimum amount to proceed is 5,000`,
            });
        }

        return status;
    }

    function handleSaveQuote() {
        if (
            isValidAmount(quote.amount) &&
            quote.term > 0 &&
            quote.usage &&
            quote.type
        ) {
            localStorage.setItem("quote", JSON.stringify(quote));
            window.parent.location.href = `${quote.sourceUrl}/application`;
        }
    }

    function handleTypeChange(type) {
        if (type === "EQUIPMENT_LOAN" || type === "BUSINESS_LOAN") {
            setQuote({ ...quote, type, usage: "COMMERCIAL_FULL_DOC" });
        } else if (type === "PERSONAL_LOAN" || type === "CAR_LOAN") {
            setQuote({ ...quote, type, usage: "CONSUMER" });
        } else {
            setQuote({ ...quote, type });
        }
    }

    function handleUsageChange(usage) {
        if (
            usage === "CONSUMER" &&
            !(quote.type === "EQUIPMENT_LOAN" || quote.type === "BUSINESS_LOAN")
        ) {
            setQuote({ ...quote, usage });
        } else if (
            usage === "COMMERCIAL_FULL_DOC" &&
            !(quote.type === "PERSONAL_LOAN")
        ) {
            setQuote({ ...quote, usage });
        }
    }

    function handleSliderChange(step) {
        setQuote({ ...quote, term: step });
    }

    function handleAmountChange(event) {
        setQuote({ ...quote, amount: removeCommas(event.target.value) });
        setError({ ...error, amount: "" });
    }

    return (
        <div className="widgetToolContainer">
            <div className="widgetTool">
                {!hideLoanType ? (
                    <>
                        <p className="widgetHeading">
                            What type of loan would you like?{" "}
                            <span style={{ color: "red" }}>*</span>
                        </p>
                        <div className="widgetButtonGroup">
                            <button
                                onClick={() => handleTypeChange("CAR_LOAN")}
                                className={`widgetButton ${
                                    quote.type === "CAR_LOAN"
                                        ? "widgetButtonActive"
                                        : ""
                                }`}
                            >
                                Car Loan
                            </button>
                            <button
                                onClick={() =>
                                    handleTypeChange("EQUIPMENT_LOAN")
                                }
                                className={`widgetButton ${
                                    quote.type === "EQUIPMENT_LOAN"
                                        ? "widgetButtonActive"
                                        : ""
                                }`}
                            >
                                Equipment Loan
                            </button>
                            <button
                                onClick={() =>
                                    handleTypeChange("PERSONAL_LOAN")
                                }
                                className={`widgetButton ${
                                    quote.type === "PERSONAL_LOAN"
                                        ? "widgetButtonActive"
                                        : ""
                                }`}
                            >
                                Personal Loan
                            </button>
                            <button
                                onClick={() =>
                                    handleTypeChange("BUSINESS_LOAN")
                                }
                                className={`widgetButton ${
                                    quote.type === "BUSINESS_LOAN"
                                        ? "widgetButtonActive"
                                        : ""
                                }`}
                            >
                                Business Loan
                            </button>
                        </div>
                    </>
                ) : null}
                {quote.type === "CAR_LOAN" && ( // only show the usage if the loan type is CAR_LOAN
                    <>
                        <p className="widgetHeading">
                            What is the purpose of the loan?{" "}
                            <span style={{ color: "red" }}>*</span>
                        </p>
                        <div className="widgetButtonGroup">
                            <button
                                onClick={() => handleUsageChange("CONSUMER")}
                                className={`widgetButton ${
                                    quote.usage === "CONSUMER"
                                        ? "widgetButtonActive"
                                        : ""
                                }`}
                            >
                                Personal Use
                            </button>
                            <button
                                onClick={() =>
                                    handleUsageChange("COMMERCIAL_FULL_DOC")
                                }
                                className={`widgetButton ${
                                    quote.usage === "COMMERCIAL_FULL_DOC"
                                        ? "widgetButtonActive"
                                        : ""
                                }`}
                            >
                                Business Use
                            </button>
                        </div>
                    </>
                )}
                <p>
                    <span className="widgetHeading">
                        What is your preferred loan term (yrs)?{" "}
                        <span style={{ color: "red" }}>*</span>
                    </span>
                </p>
                <div id="widgetSlider">
                    <span>1</span>
                    <Slider
                        onChange={(step) => {
                            handleSliderChange(step);
                        }}
                        min={1}
                        max={quote.type === "BUSINESS_LOAN" ? 3 : 7}
                        value={quote.term}
                        marks={{ [quote.term]: quote.term }}
                        dots={false}
                    />
                    <span>{quote.type === "BUSINESS_LOAN" ? 3 : 7}</span>
                </div>
                <p className="widgetHeading">
                    How much were you looking to borrow?{" "}
                    <span style={{ color: "red" }}>*</span>
                </p>
                <div id="loanAmount">
                    <span id="loanAmountSign">$</span>
                    <input
                        id="loanAmountInput"
                        onChange={handleAmountChange}
                        type="text"
                        name="amount"
                        className="loan-amount"
                        placeholder="Borrow Amount"
                        value={numberWithCommas(quote.amount)}
                    />
                    <br></br>
                    {error.amount ? (
                        <span className="toolTipText">{error.amount}</span>
                    ) : null}
                </div>
                <button
                    type="button"
                    className={`widgetProceedButton ${
                        quote.amount > 0 &&
                        quote.term > 0 &&
                        quote.usage &&
                        quote.type
                            ? ""
                            : "disabled"
                    }`}
                    onClick={handleSaveQuote}
                >
                    Get Pre-Approved
                </button>
                <p id="creditScoreText">
                    This will not impact your credit score.
                </p>
            </div>
        </div>
    );
};

export default App;
